import http from "api/https";

export const addEmployeeRecipientsRunning = (
  survey_id: number,
  employees: number[],
) => {
  let data = {
    survey_id: survey_id,
    employee_ids: employees
  }
  
  return http.post("/sm_project/SendToNewRecipients", data, );
};

