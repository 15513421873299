import { API_URL, API_TEMPLATE_URL_GO } from "constants/config";
import http from "api/https";
import { createUrl } from "../../helpers/url";

const useGetExcelStructure = (entity_id: number) => {
  const query: { entity_id: string } = { entity_id: entity_id.toString() };

  let path = API_URL + "/organization_unit/SaveToExcelStructure";
  if (http.isAPIGO) {
    path = API_TEMPLATE_URL_GO + "/employee/SaveToExcelStructure";
  }

  let url = createUrl({
    path: path,
    query: query,
  });

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    }
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  });
};

export default useGetExcelStructure; 
