import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { FC, ReactNode } from "react";

type Auth0ProviderWithNavigateProps = {
  children: ReactNode;
};

export const Auth0ProviderWithNavigate: FC<Auth0ProviderWithNavigateProps> = (props) => {
  const navigate = useNavigate();

  const domain = window["_env_"]?.REACT_APP_AUTH0_DOMAIN ?? process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId =
    window["_env_"]?.REACT_APP_AUTH0_CLIENT_ID ?? process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri =
    window["_env_"]?.REACT_APP_AUTH0_CALLBACK_URL ?? process.env.REACT_APP_AUTH0_CALLBACK_URL;

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {props.children}
    </Auth0Provider>
  );
};
