import { Outlet } from "react-router-dom";
import { FC, useState } from "react";

import styled from "styled-components";
import AppHeader from "./components/app-header/AppHeader";
import HeaderMenu from "./components/app-header/HeaderMenu";
import WorkspacesSideBar from "./components/workspaces-sidebar/WorkspacesSideBar";
import BottomProgressUploading from "components/common/BottomProgressUploading";
import BottomUploadComplete from "components/common/BottomUploadComplete";
import { observer } from "mobx-react";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import StorePermission from "./components/workspaces-sidebar/StorePermission";
import AccessRestricted from "./features/Dashboard/AccessRestricted";

type AppWrapperProps = {};

const AppWrapper: FC<AppWrapperProps> = observer(() => {
  const [openLeftSidebar, setOpenLeftSidebar] = useState(false);

  const handleOpenLeftSidebar = (state: boolean) => setOpenLeftSidebar(state);
  const allowedOutlet = ['/', '/select-workspace']

  return (
    <AppMainWapper
      $isDashboard={window.location.href.includes("dashboard")}
      id="main-scroll-content"
    >
      <AppHeader handleOpenLeftSidebar={handleOpenLeftSidebar} />
      <HeaderMenu />
      <AppContent $isImpersonated={StoreLayout.isImpersonated} $isDashboard={window.location.href.includes("dashboard")}>
        { StorePermission.hasAnyEntityPermission || allowedOutlet.includes(window.location.pathname)
          ? <Outlet />
          : <AccessRestricted />
        }
        <WorkspacesSideBar open={openLeftSidebar} handleHide={handleOpenLeftSidebar} />
      </AppContent>
      <BottomProgressUploading />
      <BottomUploadComplete />
    </AppMainWapper>
  );
});

export default AppWrapper;

const AppMainWapper = styled.div<{ $isDashboard: boolean }>`
  overflow-y: ${(props) => (props.$isDashboard ? "auto" : "hidden")};
  ${(props) => props.$isDashboard && "height: 100vh"};
  position: relative;
`;

const AppContent = styled.div<{ $isDashboard: boolean, $isImpersonated?: boolean }>`
  width: 100%;
  ${(props) => !props.$isDashboard && (props.$isImpersonated ? "height: calc(100vh - 112px)" : "height: calc(100vh - 72px)")};
  overflow-y: ${(props) => (!props.$isDashboard ? "auto" : "none")};
`;
