import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from './store'
import { useLocation } from 'react-router';
import SurveyEdit from './Survey'
import modalStore from "features/QuestionTemplate/store"

type SurveyPreViewProps = {};

const SurveyPreView: FC<SurveyPreViewProps> = observer(() => {

  let query = useQuery();
  let idTemplate = query.get('idTemplate') != null ? query.get('idTemplate') : "0"
  let idSurvey = query.get('idSurvey') != null ? query.get('idSurvey') : "0"

  useEffect(() => {
    
    let template_id = +idTemplate
    let survey_id = +idSurvey
    if (!isNaN(template_id) && template_id !== 0) {
      store.loadAllDataTemplate(+idTemplate)
      store.idTemplate = +idTemplate
      modalStore.templateId = +idTemplate
    }
    if (!isNaN(survey_id) && survey_id !== 0) {
      store.loadAllDataSurvey(survey_id)
      store.idSurvey = survey_id
    }

  }, []);


  return (
    <MainWrapper>
      <SurveyEdit />
    </MainWrapper>
  );
});

export default SurveyPreView;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MainWrapper = styled.div`
  width: 100%;
  min-height: 100vh; 
`