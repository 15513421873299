import { HTMLAttributes, ReactElement } from "react";

import styled from "styled-components";

const Container = styled.div`
  background-color: var(--overlayBackgroundColor);
  display: flex;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
`;

const FullPageLoadingIndicator = (props: HTMLAttributes<HTMLDivElement>): ReactElement => {
  return (
    <Container id="Preloader_2" role="alert" aria-label="Loading" {...props}>
      <img src={"/assets/er_logo_loading.gif"} width={64} height={64} alt="EngageRocket Logo" />
    </Container>
  );
};

export default FullPageLoadingIndicator;
