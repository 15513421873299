import { CreatorBase } from "survey-creator-core";
import {
  createDropdownActionModel,
} from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { Question } from "survey-core"

function createSortOrderAction(selectBaseQuestion: any, creator: SurveyCreator, hasEnps: boolean) {
  const sortOrderOptions = [
    { id: "rating", title: "Rating scale", iconName: "icon-rating" },
    { id: "enps", title: "eNPS", iconName: "icon-eNPS", visible: !hasEnps},
    { id: "boolean", title: "Yes/No", iconName: "icon-boolean" },
    { id: "radiogroup", title: "Single choice", iconName: "icon-radiogroup" },
    { id: "checkbox", title: "Multiple choices", iconName: "icon-checkbox" },
    { id: "comment", title: "Open-ended", iconName: "icon-comment",},
  ];

  return createDropdownActionModel(
    {
      id: "sortOrder",
      title: sortOrderOptions.filter((order) => order.id === selectBaseQuestion.classMetaData.name)[0]
      ?.title,
      visibleIndex: 8,
      location: "start",
      css: "sv-action--convertTo sv-action-bar-item--secondary sv-action--convertTo-last",
    },
    {
      items: sortOrderOptions,
      allowSelection: true,
      selectedItem: sortOrderOptions.filter((order) => order.id === selectBaseQuestion.classMetaData.name)[0],
      onSelectionChanged: (item) => {
        if(selectBaseQuestion.classMetaData.name === item.innerItem.id) return
        creator.convertCurrentQuestion(item.innerItem.id);
        creator.refreshDesigner()
      },
      verticalPosition: "bottom",
      horizontalPosition: "center",
    }
  );
}

export function changeToENPSButton({
  creator,
}: {
  creator: SurveyCreator;
}): (sender: CreatorBase, options: any) => void {
  return (sender: CreatorBase, options: any): void => {
    const question = options.obj;

    let questions = creator.survey.getAllQuestions();
    let hasEnps = true
    if (questions.filter((x) => x.getType() === "eNPS").length === 0) {
      hasEnps = false
    }
    if(question instanceof Question){
      options.items.forEach(x => {
        if(x.innerItem.id === "convertTo" || x.innerItem.id === "convertInputType"){
          x.css = "sv-action-bar-item--secondary"
        }
      })
      const sortOrderAdorner = createSortOrderAction(options.obj, creator, hasEnps);
      options.items.unshift(sortOrderAdorner);
    }

  };
}
