import { FC } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import Button from "../../components/button/Button";
import SimpleHeader from "../../components/simple-header/SimpleHeader";

type Page404Props = {};

const Page404: FC<Page404Props> = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <SimpleHeader />
      <Title>Oops!</Title>
      <Description>The page you're looking for does not exist.</Description>
      <Text>You may have mistyped the address or the page may have moved.</Text>
      <BackButton variant="text" onClick={() => navigate(-1)}>
        Go back to the previous page
      </BackButton>
      <Image src="/assets/kelly_404.png"></Image>
    </PageWrapper>
  );
};

export default Page404;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--colorNeutralForeground1);
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.96px;
  margin: 63px 0px 24px;
  color: var(--colorNeutralForeground1);
`;

const Description = styled.h2`
  width: 100%;
  text-align: center;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  padding: 0px 16px;
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  margin: 20px auto;
  max-width: 570px;
  padding: 0px 16px;
`;

const BackButton = styled(Button)`
  padding: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px dashed var(--colorBrandForeground1) !important;

  transition: all 0.3s;

  &:hover {
    background-color: transparent !important;
    color: var(--colorPaletteVioletBackground3);
    border-bottom: 1px dashed var(--colorPaletteVioletBackground3) !important;
  }
`;

const Image = styled.img`
  width: 440px;
  height: 254px;
  margin: 32px;
`;
