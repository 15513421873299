import { makeAutoObservable, reaction } from "mobx";
import {
  EntityType,
  GetMyWorkspacesNavbar,
  GetMyWorkspacesNavbarType,
  WorkSpaceType,
} from "api/go/useGetMyWorkspacesNavbar";
import { getImpersonatedInfo } from "api/go/useGetImpersonatedInfo";
import { useGetPermissions } from "api/dasboard/useGetPermissions";
import { GetMyInfo } from "api/go/useGetMyInfo";
import { UpdateAccount } from "api/go/useUpdateAccount";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { API_TEMPLATE_URL_GO } from "constants/config";
import MainStore from "MainStore";
import storePermission from "components/workspaces-sidebar/StorePermission";
import { Step } from "constants/layout-types";
import { SELECTED_ACTION_REQUIRED } from "constants/import-emploee";
import { uploadExcel, cancelImport, importExcel, reviewImport } from "api/useUploadExcelImport";
import { useLogoutImpersonated } from "api/go/useLogoutImpersonated";

class store {
  currentEntityId: number = 0;
  currentEntityName: string = "";
  currentWorkspaceId: number = 0;
  currentWorkspaceName: string = "";
  currentRegionId: number = 0;
  currentUserEmail: string = "";
  currentUserFullName: string = "";
  currentUserPreferredName: string = "";
  currentUserFullNameEdit: string = "";
  currentUserId: number = 0;
  openModalQuestion: boolean = true;
  openModalEdit: boolean = false;

  isImpersonated: boolean = false;
  impersonatedEmail: string = "";

  isAdmin: boolean = true;
  isManager: boolean = true;
  isUnitHead: boolean = false;

  importSelectedMergeChoice: string = null;
  importSelectedFile: File = null;
  importDoneAdded: number = 0;
  importDoneUpdated: number = 0;
  importDoneDeleted: number = 0;

  importDoneLink: string = "";
  importDoneText: string = "";
  importProgressText: string = "";
  showImportProgress: boolean = false;
  showImportDone: boolean = false;
  currentProgress: number = 0;
  totalProgress: number = 100;

  lockEdit: boolean = false;
  importErrors: any[] = [];
  importCurrentStep: Step | null = null;
  currentStep: string = "";

  connection: HubConnection = null;

  entityId: number = 0;
  workspaceId: number = 0;

  successImport: boolean = false;
  fileName: string = null;
  changeLogs: any[] = [];
  isFirstImport: boolean = false;

  constructor() {
    makeAutoObservable(this);
    setInterval(this.autoCheckImpersonatedInfo, 60000);

    reaction(
      () => [this.currentEntityName],
      () => {
        localStorage.setItem("EntityName", this.currentEntityName);
      }
    );

    this.currentEntityId = Number(localStorage.getItem("idEntity"));
    this.currentEntityName = localStorage.getItem("EntityName");
    this.currentWorkspaceId = Number(localStorage.getItem("idWorkspace"));
    this.currentWorkspaceName = localStorage.getItem("WorkspaceName");
    this.currentRegionId = Number(localStorage.getItem("idRegion"));

    this.currentUserFullNameEdit = "";
    this.currentUserPreferredName = "";

    this.connection = new HubConnectionBuilder()
      .withUrl(`${API_TEMPLATE_URL_GO}/employee/hubs`, {
        withCredentials: false,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();
  }

  data: GetMyWorkspacesNavbarType[] = [];

  setCurrentWorkspaceId(id: number) {
    this.currentWorkspaceId = id
  }

  changeSuccessImport = (flag: boolean) => {
    this.successImport = flag;
  };

  changeFileName = (value: string) => {
    this.fileName = value;
  };

  setEntityId(id: number) {
    this.entityId = id;
  };

  setWorkspaceId(id: number) {
    this.workspaceId = id;
  };

  handleConfirmClick = async (currentStep?: string) => {
    this.currentStep = currentStep
    this.changeProgressAndStep(
      "No changes can be made to the employee list while importing"
    );
    try {
      const params = {
        replace:
          this.importSelectedMergeChoice === SELECTED_ACTION_REQUIRED.replace_entire_list,
        identity: this.currentEntityId,
        file: this.importSelectedFile,
        currentStep: currentStep,
      };

      const response = await uploadExcel(params);

      if (response?.status === 201 || response?.status === 200) {
        if (currentStep === Step.ReviewChanges) {
          this.changeLogs = response.data.changelogs || [];
          this.changeReviewStep();
          this.changeSuccessImport(true);
        }
        if (currentStep === Step.ActionRequired) {
          if (response.data.errors?.length > 0)
            {
              this.importErrors = response.data.errors
              this.importCurrentStep = Step.Error
            }else{          
              localStorage.setItem("fileGuid", response.data.fileGuid);
              this.importDoneUpdated = response.data.countUpdate;
              this.importDoneDeleted = response.data.countDelete;
              this.importDoneAdded = response.data.countAdd;
              this.isFirstImport = response.data.is_first;
              this.changeLogs = [];
              if (response.data.is_first) {
                this.changeReviewStep()
              } else {
                this.changeActionRequiredStep(response.data);
              }
            }
        }
      } else if (response?.response?.status === 422) {
          MainStore.setSnackbar(response?.response?.data?.error, "error");
        this.clearStoreImportSelectedFile();
      } else {
        throw response;
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to upload", "error");
      this.clearStoreImportSelectedFile();
    }
  };

  handleConfirmImportClick = async (currentStep?: string) => {
    this.currentStep = currentStep
    this.changeProgressAndStep(
      "No changes can be made to the employee list while importing"
    );
    try {
      const params = {
        replace:
          this.importSelectedMergeChoice === SELECTED_ACTION_REQUIRED.replace_entire_list,
        identity: this.currentEntityId,
        currentStep: currentStep,
      };

      const response = await importExcel(params);

      if (response?.status === 201 || response?.status === 200) {
        if (currentStep === Step.ReviewChanges) {
          this.changeLogs = response.data.changelogs || [];
          localStorage.removeItem("fileGuid");

          this.changeShowImportProgress(false);
          this.changeImportDoneText("Upload complete! Your confirmation is needed on changes to your employee list before it can be updated.");
          this.changeShowImportDone(true);
          this.importDoneLink = "/employees-import";

          this.changeReviewStep();
          this.changeSuccessImport(true);
        }
        if (currentStep === Step.ActionRequired) {
          this.changeLogs = [];
          this.changeActionRequiredStep(response.data);
        }
      } else if (response?.status === 504) {
        //outside spacetime
      } else if (response?.response?.status === 422) {
        const errDetail = response.response?.data.details?.find(
          (d: { field: string }) => d.field == "managers_email"
        );
        if (errDetail?.message) {
          MainStore.setSnackbar(errDetail.message, "error");
        } else {
          MainStore.setSnackbar(response?.response?.data?.error, "error");
        }
        this.clearStoreImportSelectedFile();
      } else {
        throw response;
      }
    } catch (err) {
      if (err?.status === 422) {
        MainStore.setSnackbar("Failed to upload", "error");
        this.clearStoreImportSelectedFile();
      }
    }
  };

  saveEditEntityHandler = async () => {
    await this.doLoad();
    if (this.entityId === this.currentEntityId) {
      const nameEntity = this.data[0]?.workspaces
        ?.find((el) => el.id === this.workspaceId)
        ?.entities.find((el) => el.id === this.entityId)?.name;
      this.setCurrentEntityName(nameEntity);
    }
  };

  saveEntity = async () => {
    const account = {
      id: this.currentUserId,
      full_name: this.currentUserFullNameEdit,
      preferred_name: this.currentUserPreferredName,
      email: this.currentUserEmail,
    };

    await UpdateAccount(account);
    this.clearStore();
  };

  setItemLocalStorage = (workspaces: WorkSpaceType[]) => {
    const first = workspaces[0];
    const firstId = first?.id ?? 0;
    const firstName = first?.name ?? "";
    localStorage.setItem("idWorkspace", firstId.toString());
    localStorage.setItem("WorkspaceName", firstName);
    localStorage.setItem("idEntity", "");
    localStorage.setItem("EntityName", "");
    if (!window.location.href.includes("/select-workspace"))
      window.location.href = "/select-workspace";
  };

  clearItemLocalStorage = () => {
    localStorage.setItem("idWorkspace", "0");
    localStorage.setItem("WorkspaceName", "");
    localStorage.setItem("idEntity", "");
    localStorage.setItem("EntityName", "");
    if (!window.location.href.includes("/select-workspace"))
      window.location.href = "/select-workspace";
  };

  autoCheckImpersonatedInfo = async () => {
    const impersonatedInfo = await getImpersonatedInfo();
    this.isImpersonated = impersonatedInfo?.data?.is_impersonated || false;
    if (this.isImpersonated && this.impersonatedEmail !== "" && this.impersonatedEmail !== impersonatedInfo?.data?.email) {
      window.location.reload();
    }
  }

  doLoad = async () => {
    const impersonatedInfo = await getImpersonatedInfo();
    this.isImpersonated = impersonatedInfo?.data?.is_impersonated || false;
    this.impersonatedEmail = impersonatedInfo?.data?.email || "";

    const workspacesNavbar = await GetMyWorkspacesNavbar();
    this.showImportDone = false;
    this.data = workspacesNavbar?.data || [];
    const workspaces = this.data[0]?.workspaces ?? [];

    if (this.currentEntityId === 0) {
      const workspace = workspaces.find(
        (workspace) => workspace.id === this.currentWorkspaceId && workspace.has_access,
      );
      if (!workspace) {
        this.setItemLocalStorage(workspaces);
      } else {
        if (workspace.id === this.currentWorkspaceId) return;
        this.clearItemLocalStorage();
      }
      return;
    }

    let entity: EntityType = null;
    for (let i = 0; i < workspaces.length; i++) {
      entity = workspaces[i].entities.find((entity) => entity.id === this.currentEntityId);
      if (entity) break;
    }
    if (!entity) {
      const workspace = workspaces.find(
        (workspace) => workspace.id === this.currentWorkspaceId && workspace.has_access,
      );
      if (!workspace) {
        this.setItemLocalStorage(workspaces);
      } else {
        if (workspace.id === this.currentWorkspaceId) {
          if (workspace.entities.some((entity) => entity.id === this.currentEntityId)) {
            return;
          }
        }
        this.clearItemLocalStorage();
      }
    }
  };

  selectWorkspace = (workspace: WorkSpaceType) => {
    this.currentEntityId = 0;
    this.currentEntityName = "";
    this.currentWorkspaceId = workspace.id;
    this.currentWorkspaceName = workspace.name;
    this.currentRegionId = workspace.region_id;

    localStorage.setItem("idEntity", "");
    localStorage.setItem("EntityName", "");
    localStorage.setItem("idWorkspace", workspace.id.toString());
    localStorage.setItem("WorkspaceName", workspace.name);
    localStorage.setItem("idRegion", workspace.region_id.toString());

    window.location.href = "/entities";
  };

  getMyInfo = async () => {
    const userInfo = await GetMyInfo();
    this.openModalEdit = false;
    this.currentUserEmail = userInfo.data.email;
    localStorage.setItem("userEmail", userInfo.data.email);
    this.currentUserFullName = userInfo.data.full_name;
    this.currentUserFullNameEdit = userInfo.data.full_name;
    this.currentUserPreferredName = userInfo.data.preferred_name;
    this.currentUserId = userInfo.data.id;

    if (!this.currentUserFullName?.trim() || !this.currentUserPreferredName?.trim()) {
      this.openModalEdit = true;
    }
  };

  setLockEdit = (flag: boolean) => {
    this.lockEdit = flag;
  };

  setCurrentEntityName = (flag: string) => {
    this.currentEntityName = flag;
    return this.currentEntityName;
  };

  changeFullName = (value: string) => {
    this.currentUserFullNameEdit = value;
  };

  changePreferredName = (value: string) => {
    this.currentUserPreferredName = value;
  };

  clearStore = () => {
    this.openModalQuestion = false;
    this.openModalEdit = false;
  };

  selectEntity = (entity: EntityType, workspace: WorkSpaceType) => {
    this.currentEntityId = entity.id;
    this.currentEntityName = entity.name;
    this.currentWorkspaceId = workspace.id;
    this.currentWorkspaceName = workspace.name;
    this.currentRegionId = entity.region_id;

    localStorage.setItem("idEntity", entity.id.toString());
    localStorage.setItem("EntityName", entity.name);
    localStorage.setItem("idWorkspace", workspace.id.toString());
    localStorage.setItem("WorkspaceName", workspace.name);
    localStorage.setItem("idRegion", entity.region_id.toString());

    window.location.href = "/dashboard";
  };

  getPermissions = async () => {
    if (this.currentEntityId === 0) return;
    try {
      const response = await useGetPermissions(this.currentEntityId);
      if (response) {
        this.isAdmin = response.isAdmin;
        this.isManager = response.isManager;
        this.isUnitHead = response.isUnitHead;
      }
    } catch (error) {
      console.error(error);
    }
  };

  logout = async () => {
    try {
      await useLogoutImpersonated(this.currentRegionId);
    } catch (error) {
      MainStore.setSnackbar("Something went wrong", "error");
    }
  };

  changeImportCurrentStep = (step: Step) => {
    this.importCurrentStep = Step[step];
  };

  changeImportCancel = async () => {
    try {
      const params = {
        identity: StoreLayout.currentEntityId,
      };

      MainStore.changeLoader(true);
      const response = await cancelImport(params);
      this.clearStoreImportSelectedFile()
      localStorage.removeItem("fileGuid");

      if (response?.status === 201 || response?.status === 200) {
        MainStore.changeLoader(false);
      } else if (response?.response?.status === 422) {
        MainStore.setSnackbar(response?.response?.data?.error, "error");
      } else {
        throw response;
      }
    } catch (err) {
      if (err?.status === 422) {
        MainStore.setSnackbar("Failed to cancel import", "error");
      }
    }
  };

  clickReviewImport = async () => {
    try {
      const params = {
        identity: StoreLayout.currentEntityId,
        replace: this.importSelectedMergeChoice === SELECTED_ACTION_REQUIRED.replace_entire_list,
      };

      MainStore.changeLoader(true);
      const response = await reviewImport(params);

      if (response?.status === 201 || response?.status === 200) {
        MainStore.changeLoader(false);
        this.importDoneUpdated = response.data.countUpdate;
        this.importDoneDeleted = response.data.countDelete;
        this.importDoneAdded = response.data.countAdd;
      } else if (response?.response?.status === 422) {
        MainStore.setSnackbar(response?.response?.data?.error, "error");
      } else {
        throw response;
      }
    } catch (err) {
      if (err?.status === 422) {
        MainStore.setSnackbar("Failed to review import", "error");
      }
    }
  };

  changeImportSelectedMergeChoice = (choice: string) => {
    this.importSelectedMergeChoice = choice;
  };

  clearStoreImportSelectedFile = () => {
    this.importSelectedFile = null;
    this.importCurrentStep = null;
    this.importDoneUpdated = 0;
    this.importDoneDeleted = 0;
    this.importDoneAdded = 0;
    this.fileName = null;
    this.showImportProgress = false;
    this.showImportDone = false;
    this.lockEdit = false;
  };

  changeProgressAndStep = (message: string) => {
    this.showImportProgress = true;
    this.importProgressText = message;
    this.importCurrentStep = Step.ProgressLoader;
  };

  changeReviewStep = () => {
    this.importSelectedFile = null;
    this.importSelectedMergeChoice = null;
    this.importCurrentStep = Step.ReviewChanges;
  };

  changeActionRequiredStep = (data: {
    countUpdate: number;
    countDelete: number;
    countAdd: number;
  }) => {
    this.importDoneUpdated = data.countUpdate;
    this.importDoneDeleted = data.countDelete;
    this.importDoneAdded = data.countAdd;
    this.importCurrentStep = Step.ActionRequired;
  };

  handleImportSelectedFile = (file: File) => {
    this.importSelectedFile = file;
    this.importCurrentStep = Step.ActionRequired;
  };

  changeCurrentAndTotalProgress = (progress: number) => {
    this.currentProgress = progress;
    this.totalProgress = 1;
  };

  changeImportDoneText = (text: string) => {
    this.importDoneText = text;
  };

  changeShowImportDone = (flag: boolean) => {
    this.showImportDone = flag;
  };

  changeShowImportProgress = (flag: boolean) => {
    this.showImportProgress = flag;
  };

  get progress() {
    if (this.totalProgress === 0) {
      return null;
    }
    return Math.floor((this.currentProgress * 100) / this.totalProgress);
  }
}

const StoreLayout = new store();
export default StoreLayout;
