import clsx from "clsx";
import * as React from "react";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import { styled } from "styled-components";
import { unstable_useBlocker as useBlocker, useNavigate } from "react-router-dom";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  TreeItem,
  treeItemClasses,
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from "@mui/x-tree-view/TreeItem";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, Paper, TextField } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import AsynchronousTextField from "./employeeSearch";
import CustomButton from "components/button/Button";
import CustomModal from "components/modal/Modal";
import store from "./store";
import storeAttributeList from "../../Employees/AttributeList/store";
import ChevronCircleIcon from "features/Survey/Recipients/ChevronCircleIcon";
import NavStore from "./../store";

declare module "@mui/x-tree-view/TreeItem" {
  interface TreeItemContentProps {
    level: number;
    emails: string[];
    edit: boolean;
    levelsHidded: number;
    isNew: boolean;
    countChildren: number;
    id: number;
  }
}

export interface NestedStructure {
  id: number;
  name: string;
  emails: string[];
  details?: string | null;
  description?: string | null;
  virtual_id?: number | null;
  parent_id?: number | null;
  virtual_parent_id?: number | null;
  level: number;
  edit: boolean;
  isNew: boolean;
  idTypeName?: string | null;
  children: NestedStructure[];
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const {
    classes,
    className,
    level,
    emails,
    label,
    edit,
    levelsHidded,
    id,
    nodeId,
    isNew,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (store.isFormChanged) {
      return currentLocation.search !== nextLocation.search;
    }
  });

  const { disabled, expanded, selected, focused, handleExpansion, preventSelection } =
    useTreeItem(nodeId);

  const [updateKey, setUpdateKey] = React.useState(0);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    preventSelection(event);

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    handleExpansion(event);

  const modalBlocker = (
    <CustomModal open={blocker.state === "blocked"} hide={() => blocker.reset()}>
      <StyledWrapperModalBlocker>
        <h2 id={`Employees_OrganizationalStructure_BlocerPopUp_Title`}>
          Are you sure you want to leave without saving changes?
        </h2>
        <StyledWrapperBlockerList>
          <p id={`Employees_OrganizationalStructure_BlocerPopUp_Subtitle`}>
            Setting up an organizational structure would help your organization:
          </p>
          <ul>
            <li id={`Employees_OrganizationalStructure_BlocerPopUp_Body`}>
              View reports on the dashboard based on your organizational structure.
            </li>
            <li id={`Employees_OrganizationalStructure_BlocerPopUp_Footer`}>
              Easily set up permissions for specific users/ groups on EngageRocket's platform.
            </li>
          </ul>
        </StyledWrapperBlockerList>
        <StyledWrapperBlockerButtons>
          <CustomButton
            id={`Employees_OrganizationalStructure_BlocerPopUp_StayOnThisPage_Btn`}
            variant="contained"
            onClick={() => blocker.reset()}
          >
            Stay on this page
          </CustomButton>
          <CustomButton
            id={`Employees_OrganizationalStructure_BlocerPopUp_LeaveThisPage_Btn`}
            variant="outlined"
            onClick={() => blocker.proceed()}
          >
            Leave this page
          </CustomButton>
        </StyledWrapperBlockerButtons>
      </StyledWrapperModalBlocker>
    </CustomModal>
  );

  return (
    <>
      {modalBlocker}
      <ContentContainer
        id={`Employees_OrganizationalStructure_RowLevel_${level}_${id}`}
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        <div
          id={`Employees_OrganizationalStructure_RowLevel_Panel_${level}_${id}`}
          onClick={handleExpansionClick}
          className={classes.iconContainer}
          style={{ width: 30 }}
        >
          {icon}
        </div>
        <div className={classes.label}>
          {edit ? (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <div>
                <StyledLevelText
                  id={`Employees_OrganizationalStructure_RowLevel_LevelName_${level}_${id}`}
                >
                  LEVEL {level}
                </StyledLevelText>
                {level !== 0 && (
                  <StyledWithIconButton
                    id={`Employees_OrganizationalStructure_RowLevel_RemoveBtn_${level}_${id}`}
                    onClick={() => store.removePanelOpen(id, level, label + "", isNew)}
                    style={{ marginLeft: 20 }}
                  >
                    <Delete fontSize="small" />
                    Delete
                  </StyledWithIconButton>
                )}
              </div>
              <StyledWithIconButton
                id={`Employees_OrganizationalStructure_RowLevel_CloseBtn_${level}_${id}`}
                disabled={label === ""}
                onClick={() => store.changeField(id, "edit", false)}
              >
                Hide
                <ChevronCircleIcon
                  outlinedColor="var(--colorNeutralForeground4)"
                  chevronColor="var(--colorBrandForeground1)"
                />
              </StyledWithIconButton>
            </Box>
          ) : (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <div>
                <StyledLevelText
                  id={`Employees_OrganizationalStructure_RowLevel_LevelName_${level}_${id}`}
                >
                  LEVEL {level}
                </StyledLevelText>
                <StyledUnitName
                  id={`Employees_OrganizationalStructure_RowLevel_LevelLabel_${level}_${id}`}
                >
                  {label}
                </StyledUnitName>

                {emails?.length > 0 ? (
                  emails.map((mail, indexMail) => (
                    <StyledChip
                      id={`Employees_OrganizationalStructure_RowLevel_LevelLabel_Mail_${indexMail}_${level}_${id}`}
                      key={mail}
                      sx={{ ml: 1 }}
                      label={mail}
                      size="small"
                      icon={<EmailIcon />}
                    />
                  ))
                ) : (
                  <StyledChip
                    id={`Employees_OrganizationalStructure_RowLevel_LevelEmail_${level}_${id}`}
                    sx={{ ml: 1 }}
                    label={"Add unit head email (optional)"}
                    onClick={() => store.changeField(id, "edit", true)}
                    size="small"
                    icon={<EmailIcon />}
                  />
                )}
              </div>
              <StyledEditButton
                id={`Employees_OrganizationalStructure_RowLevel_EditBtn_${level}_${id}`}
                onClick={() => store.changeField(id, "edit", true)}
                disabled={(NavStore.importLock ?? false)}
              >
                <Edit fontSize="small" />
                Edit
              </StyledEditButton>
            </Box>
          )}

          {edit && (
            <Box sx={{ m: 1 }}>
              <TextField
                id={`Employees_OrganizationalStructure_RowLevel_Edit_Input_${level}_${id}`}
                fullWidth
                variant="standard"
                required
                placeholder="Unit name"
                value={label}
                error={label === ""}
                onChange={(e) => store.changeField(id, "name", e.target.value)}
              />
              <Box display={"flex"} sx={{ mt: 1 }}>
                <LabelUnitEmail
                  id={`Employees_OrganizationalStructure_RowLevel_Edit_Info_${level}_${id}`}
                  style={{ marginTop: 10, minWidth: 200 }}
                >
                  Unit head email(optional)
                </LabelUnitEmail>
                <AsynchronousTextField
                  id={id}
                  level={level}
                  clickEmail={(email: string) => store.addEmail(id, email)}
                  setUpdateKey={setUpdateKey}
                />
              </Box>
              <Box sx={{ mt: 1, mb: 1 }} key={updateKey}>
                {emails &&
                  emails.map((mail, i) => {
                    return (
                      <StyledChip
                        id={`Employees_OrganizationalStructure_RowLevel_Edit_AddeEmail_${i}_${level}_${id}`}
                        key={mail}
                        sx={{ ml: 1 }}
                        onDelete={() => store.deleteEmail(id, mail)}
                        label={mail}
                        size="small"
                        icon={<EmailIcon />}
                        deleteIcon={
                          <CloseIcon
                            id={`Employees_OrganizationalStructure_RowLevel_Edit_AddeEmail_RemoveBtn_${i}_${level}_${id}`}
                            style={{ fill: "var(--colorNeutralForeground2)" }}
                          />
                        }
                      />
                    );
                  })}
              </Box>
            </Box>
          )}
        </div>
      </ContentContainer>
      {!expanded && levelsHidded !== 0 && (
        <ContentContainer style={{ marginLeft: 20, cursor: "pointer" }} onClick={handleExpansionClick}>
          <div
            style={{
              borderLeft: `3px dashed var(--colorPaletteBlueBackground2)`,
            }}
          >
            <LevelsHiddedText>
              {!expanded &&
                levelsHidded !== 0 &&
                `${levelsHidded} ${levelsHidded === 1 ? "level" : "levels"} hidden`}
            </LevelsHiddedText>
          </div>
        </ContentContainer>
      )}
    </>
  );
});

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: TreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
});

const StyledTreeItem = styled(CustomTreeItem)(() => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    borderLeft: `3px dashed var(--colorPaletteBlueBackground2)`,
  },
}));

const CustomizedTreeView = observer(() => {
  const navigate = useNavigate();

  const renderTree = (nodes: NestedStructure) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id + ""}
      label={nodes.name}
      ContentProps={
        {
          emails: nodes.emails,
          level: nodes.level,
          id: nodes.id,
          isNew: nodes.isNew,
          edit: nodes.edit,
          countChildren: nodes.children ? nodes.children.length : 0,
          levelsHidded: nodes.children.length,
        } as any
      }
    >
      {Array.isArray(nodes.children) && nodes.children.map((node) => renderTree(node))}

      <StyledAddLevelButton
        id={`Employees_OrganizationalStructure_AddLevel_Btn_${nodes.id}_${nodes.level + 1}`}
        onClick={() => store.addNode(nodes.id)}
        sx={{ mt: 1 }}
        disabled={nodes.level === 25 || (NavStore.importLock ?? false)}
        variant="outlined"
      >
        <PlusFragment>
          <AddIcon fontSize="small" />
        </PlusFragment>{" "}
        Add level {nodes.level + 1}
      </StyledAddLevelButton>
    </StyledTreeItem>
  );

  return (
    <>
      <Paper sx={{ maxWidth: 800, p: 2 }}>
        <Box
          id={`Employees_OrganizationalStructure_EntityTitle`}
          sx={{ m: 2, fontWeight: 600, fontSize: 20 }}
        >
          {StoreLayout.currentEntityName} organization structure
        </Box>

        {store.data && (
          <StyledBox sx={{ minHeight: 570, flexGrow: 1 }}>
            <TreeView
              aria-label="customized"
              expanded={store.expandedIds}
              onNodeToggle={(x, y) => (store.expandedIds = y)}
              defaultCollapseIcon={
                <ArrowDropDownIcon style={{ width: 30, height: 30 }} fontSize="inherit" />
              }
              defaultExpandIcon={
                <ArrowRightIcon style={{ width: 30, height: 30 }} fontSize="inherit" />
              }
              defaultExpanded={['1']}
            >
              {renderTree(store.data)}
            </TreeView>
          </StyledBox>
        )}
      </Paper>
      <WrapperButtons>
        <div>
          <p id={`Employees_OrganizationalStructure_FooterText_1`}>
            All levels must be named in order to save structure.
          </p>
          <CustomButton
            id={`Employees_OrganizationalStructure_SaveSrtucture_Btn`}
            variant="contained"
            disabled={!store.checkNamesInData() || !store.isFormChanged}
            onClick={() => store.SaveStructure()}
          >
            Save structure
          </CustomButton>
        </div>

        {store.levelStructure && (
          <div>
            <div>
              <h3 id={`Employees_OrganizationalStructure_FooterTex_2_Title`}>Set up attributes</h3>
              <p id={`Employees_OrganizationalStructure_FooterTex_2_Subtitle`}>
                Setup attributes to help you slice and dice your survey data.
              </p>
            </div>
            <CustomButton
              id={`Employees_OrganizationalStructure_AddAtributes_Btn`}
              style={{ width: 180 }}
              variant="contained"
              onClick={() => {
                navigate("/employees?nav=attributeList");
                storeAttributeList.setOpenPanel(true);
              }}
            >
              + Add attributes
            </CustomButton>
          </div>
        )}
      </WrapperButtons>

      <CustomModal open={store.openPanelRemove} hide={(flag) => store.changeRemovePanel(flag)}>
        <DeleteModalBlock>
          <DeleteModalHeader id={`Employees_OrganizationalStructure_DeleteLEvelPopUp_Title`}>
            Are you sure you want to remove Level {store.removePanelLevel} {store.removePanelName}?
          </DeleteModalHeader>
          <br />

          <DeleteModalBody id={`Employees_OrganizationalStructure_DeleteLEvelPopUp_Body`}>
            There are levels within this level. All the levels under this level will be deleted as
            well.
            <br /> <br /> <br />
            {store.removePanelCountEmployees === 1 &&
              `There is ${store.removePanelCountEmployees} employee associated with this organization and its subsidiaries. Are you sure you want to delete all structures?`}
            {store.removePanelCountEmployees > 0 &&
              `There are ${store.removePanelCountEmployees} employees associated with this organization and its subsidiaries. Are you sure you want to delete all structures?`}
          </DeleteModalBody>

          <Box sx={{ mt: 5 }} display={"flex"}>
            <Box sx={{ m: 1 }}>
              <CustomButton
                id={`Employees_OrganizationalStructure_DeleteLEvelPopUp_Remove_btn`}
                onClick={() => store.removeNode(store.removePanelId)}
                variant="contained"
              >
                Remove
              </CustomButton>
            </Box>
            <Box sx={{ m: 1 }}>
              <CustomButton
                id={`Employees_OrganizationalStructure_DeleteLEvelPopUp_Camsel_Btn`}
                onClick={() => store.changeRemovePanel(false)}
                variant="contained"
                color="inherit"
              >
                Cancel
              </CustomButton>
            </Box>
          </Box>
        </DeleteModalBlock>
      </CustomModal>
    </>
  );
});

export default CustomizedTreeView;

const StyledWrapperModalBlocker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  padding: 40px;

  > h2 {
    margin: 0;
    color: var(--colorNeutralForeground1);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
`;

const StyledWrapperBlockerList = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  ul {
    margin-top: 30px;
    padding-inline-start: 20px;
  }
`;

const StyledWrapperBlockerButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  > :last-child {
    color: var(--colorNeutralForeground2);
    border: 1px solid var(--colorNeutralForeground5);
  }
`;

const LevelsHiddedText = styled.div`
  color: var(--colorBrandForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 20px;
  padding-left: 10px;
  margin-bottom: 15px;
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  margin-top: 20px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      > h3 {
        margin: 0;
        color: var(--colorNeutralForeground1);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    p {
      color: var(--colorNeutralForeground2);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

const DeleteModalBlock = styled.div`
  margin: 50px;
`;

const DeleteModalHeader = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const DeleteModalBody = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ContentContainer = styled.div`
  margin: 5px;
  padding: 5px 20px 5px 5px;
  border: 1px solid var(--colorNeutralForeground4);
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const StyledBox = styled(Box)`
  .MuiTreeItem-content {
    align-items: flex-start !important;
  }
`;

const StyledEditButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const StyledWithIconButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledChip = styled(Chip)`
  background-color: var(--colorNeutralBackground3) !important;
  padding: 0 10px !important;
  svg {
    width: 16px;
    fill: var(--colorNeutralForeground6);
  }
`;

const StyledAddLevelButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--colorPaletteGrayBackground1) !important;
  width: 147px;
`;

const PlusFragment = styled.p`
  background-color: var(--colorNeutralBackground1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

const StyledLevelText = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const StyledUnitName = styled.span`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0 20px;
`;

const LabelUnitEmail = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
