import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { observer } from "mobx-react"
import Dialog from '../../../../components/modal/Modal'
import { Box } from "@mui/material";
import CustomButton from "components/button/Button";
import styled from "styled-components";
import storePermissions from "features/EntityPermissions/User/store"


type RemoveFromRoleProps = {
  id?:string;
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: () => void;
  onClose: () => void;
  countUsers?: number;
  idUsers: number[];
};

const RemoveFromRole: FC<RemoveFromRoleProps> = observer((
  props
): ReactElement => {

  useEffect(() => {

  }, [props.openPanel])

  const selected = storePermissions.invitedUsers.find((x) => x.id === props.idUsers[0]);

  return (
    <Dialog
      open={props.openPanel}
      hide={() => { props.onClose() }}
      aria-label="Invite users again"
    >

      <MainWrapper>
        <Header id={props.id && `${props.id}_Title`}>
          Are you sure you want to invite {props.countUsers === 1 ? selected?.email : `${props.countUsers} users` } via email again?
        </Header>

        <Body id={props.id && `${props.id}_Body`}>
          They will be sent an invite email immediately. The invite will be valid for 5 days from today.
        </Body>

        <ButtonsWrapper>

          <ButtonWrapper>
            <CustomButton
            id={props.id && `${props.id}_Yes_Btn`}
              onClick={() => {
                store.inviteAgainSubmit(props.idUsers, () => {
                  props.onSaved()
                })
              }} variant="contained" >
              Yes, invite users again
            </CustomButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CustomButton
            id={props.id && `${props.id}_Cansel_Btn`}
            onClick={() => { props.onBtnCancelClick() }} variant="contained" color="inherit">
              Cancel
            </CustomButton>
          </ButtonWrapper>

        </ButtonsWrapper>

      </MainWrapper>
    </Dialog >
  );
});


const MainWrapper = styled.div`
  margin: 50px 50px 20px 50px;
`;


const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Body = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 20px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  margin: 8px;
`;



export default RemoveFromRole;
