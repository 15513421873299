import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import CustomModal from "components/modal/Modal";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import StoreLayout from "./../workspaces-sidebar/StoreLayout";
import { AppsIcon } from "./AppsIcon";
import StyledAvatar from "../avatar/Avatar";
import Button from "../button/Button";
import EditModal from "./EditModal";

type AppHeaderProps = {
  handleOpenLeftSidebar: (state: boolean) => void;
};

const AppHeader: FC<AppHeaderProps> = observer((props) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    StoreLayout.getMyInfo();
  }, []);

  return (
    <>
      <CustomModal
        id={"SetEngageRocketName"}
        width="500px"
        closeIcon={false}
        open={StoreLayout.openModalEdit}
        hide={() => {}}
      >
        <EditModal />
      </CustomModal>

      {StoreLayout.isImpersonated && (
        <ImpersonatedBar>
          You are viewing the platform as: {StoreLayout.impersonatedEmail}
        </ImpersonatedBar>
      )}

      <AppHeaderWrapper>
        <ContentContainer>
          <StyledButton
            id={"MainHeaderLogoButton"}
            variant="text"
            onClick={() => props.handleOpenLeftSidebar(true)}
          >
            <AppsIcon />
            <PageName id={"MainHeaderWorkspaceAndEntityName"}>
              {StoreLayout.currentWorkspaceName +
                (StoreLayout.currentEntityId !== 0 ? ": " + StoreLayout.currentEntityName : "")}
            </PageName>
          </StyledButton>
        </ContentContainer>
        <ContentContainer>
          <NavigateMenu>
            <MenuLink id={"MainHeaderPricingMenu"}>Pricing</MenuLink>
            <MenuLink id={"MainHeaderHelpMenu"}>HELP</MenuLink>
          </NavigateMenu>
          <HeaderLogo
            id={"MainHeaderEngagerRocketLogo"}
            src="/assets/er-logo-square.png"
            alt="header-logo"
          />
          <Divider />

          <IconButton
            id={"MainHeaderUserButton"}
            aria-label="header-avatar-menu"
            onClick={handleOpenNavMenu}
          >
            <StyledAvatar width={40} height={40} alt="header-avatar" src="" />
          </IconButton>
          <StyledMenu
            id={"MainHeaderUserMenu"}
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuHeader id={"MainHeaderUserMenuUserInfo"}>
              <MenuName id={"MainHeaderUserMenuUserPreferedName"}>
                {StoreLayout.currentUserPreferredName}
              </MenuName>
              <MenuEmail id={"MainHeaderUserMenuUserEmail"}>
                {StoreLayout.currentUserEmail}
              </MenuEmail>
            </MenuHeader>
            <MenuItem
              id={"MainHeaderUserMenuAccountSettingsButton"}
              onClick={() => navigate("/account-settings")}
            >
              Account settings
            </MenuItem>
            <MenuItem
              id={"MainHeaderUserMenuLogoutButton"}
              onClick={async () =>{
                await StoreLayout.logout()
                logout({
                  logoutParams: {
                    returnTo:
                      window["_env_"]?.REACT_APP_AUTH0_CALLBACK_URL ??
                      process.env.REACT_APP_AUTH0_CALLBACK_URL,
                  },
                })
              }
              }
            >
              Sign out
            </MenuItem>
          </StyledMenu>
        </ContentContainer>
      </AppHeaderWrapper>
    </>
  );
});

export default AppHeader;

const AppHeaderWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorPaletteVioletBackground1);
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: inherit;
`;

const PageName = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralBackground1);
  margin-left: 22px;
`;

const NavigateMenu = styled.ul`
  display: flex;
  align-items: center;
  padding: 0 0;
`;

const MenuLink = styled.li`
  list-style-type: none;
  color: var(--colorNeutralForegroundInverted1);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.7384616136550903px;
  text-transform: uppercase;
  padding: 0 12px;
`;

const Divider = styled.div`
  width: 1px;
  height: 32px;
  color: var(--colorPaletteVioletBackground2);
  background-color: var(--colorPaletteVioletBackground2);
  margin-right: 16px;
`;

const HeaderLogo = styled.img`
  width: 32px;
  height: 32px;
  margin: 0 24px 0 20px;
`;

const StyledButton = styled(Button)``;

const MenuHeader = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorNeutralBackground3);
  margin-bottom: 8px;
  cursor: default;
`;

const MenuName = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-bottom: 4px;
`;

const MenuEmail = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0 1px 2px 0 var(--colorShadowInverted1);
    border: 1px solid var(--colorNeutralForeground4);
    border-radius: 0;
  }
  .MuiButtonBase-root {
    &:hover {
      background-color: var(--colorPalleteLightBlue);
    }
  }
`;

const ImpersonatedBar = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorPaletteYellowForeground1);
  height: 40px;
`;
