import http from "api/https";

export const getSurvey = (id: number): Promise<any> => {
  if (http.isAPIGO) {
    return http.getGO(`/sm/sm_survey/${id}`);
  } else {
    return http.get(`/sm_survey/${id}`);
  }
};

export const getSurveyForPreview = (id: number): Promise<any> => {
  if (http.isAPIGO) {
    return http.getGO(`/sm/sm_survey/Preview/${id}`);
  } else {
    return http.get(`/sm_survey/Preview/${id}`);
  }
};
