import { API_URL, API_TEMPLATE_URL_GO } from "constants/config";
import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { downloadFile } from "utils/downloadFile";
import http from "api/https";
import store from "features/ManageEntities/store";
import { format } from 'date-fns';

const downloadChangeLog = (id: string) => {
   let url = `${API_URL}/file/DownloadChangeLog?changelog_id=${id}`;
   if (http.isAPIGO) {
     url = `${API_TEMPLATE_URL_GO}/employee/DownloadChangeLog?changelog_id=${id}`;
   }
   return fetch(url, {
     headers: {
       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
     }
   }).then((response) => {
     if (!response.ok) {
       throw new Error("Network response was not ok");
     }
     return response.blob();
   });
};


const useDownloadChangeLog = async (id: string) => {
  try {
    MainStore.changeLoader(true);
    const data = await downloadChangeLog(id);
    const formattedDate = format(Date.now(), 'ddMMMyy');
    downloadFile(
      data,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      `${StoreLayout.currentEntityName}_Changelog_${formattedDate}.xlsx`,
    );
  } catch (err) {
    MainStore.setSnackbar("Something went wrong", "error");
  } finally {
    MainStore.changeLoader(false);
  }
};

export default useDownloadChangeLog