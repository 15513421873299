import { makeAutoObservable, runInAction, reaction } from "mobx";
import { useGetFeatures } from "api/go/useGetFeatures";
import { TEntityFeature, TPermission } from "types/permission";
import StoreLayout from "./StoreLayout";
import { FEATURES_SUBSCRIPTION } from "constants/features";
import {
  ENTITY_PERMISSIONS,
  PROJECT_PERMISSIONS,
  WORKSPACE_PERMISSIONS,
} from "constants/permissions";
import MainStore from "MainStore";
import { useGetMyWorkspacePermissions } from "api/go/useGetMyWorkspacePermissions";
import { useGetMyEntityPermissions } from "api/go/useGetMyEntityPermissions";
import { useGetMyProjectPermissions } from "api/go/useGetMyProjectPermissions";

class store {
  entityFeatures: TEntityFeature[] = [];
  features = FEATURES_SUBSCRIPTION;
  featuresLoaded: boolean = false;

  entityPermissions = ENTITY_PERMISSIONS;
  workspacePermissions = WORKSPACE_PERMISSIONS;
  projectPermissions = PROJECT_PERMISSIONS;
  hasAnyEntityPermission: boolean = false;
  entityPermissionsLoaded: boolean = false;
  workspacePermissionsLoaded: boolean = false;
  projectPermissionsLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
    
  }

  setFeatures = (features: TEntityFeature[]) => {
    runInAction(() => {
      this.entityFeatures = features;
      features.forEach((x) => {
        if (x.hasFeature) {
          if (this.features[x.code]) {
            this.features[x.code].hasFeature = x.hasFeature;
          }
        }
      });
      this.featuresLoaded = true;
    });
  };
  setWorkspacePermissions = (permissions: TPermission[]) => {
    runInAction(() => {
      permissions.forEach((x) => {
        if (x.hasPermission) {
          if (this.workspacePermissions[x.code]) {
            this.workspacePermissions[x.code].hasPermission = x.hasPermission;
          }
        }
      });
      this.workspacePermissionsLoaded = true;
    });
  };
  setEntityPermissions = (permissions: TPermission[]) => {
    runInAction(() => {
      permissions.forEach((x) => {
        if (x.hasPermission) {
          if (this.entityPermissions[x.code]) {
            this.entityPermissions[x.code].hasPermission = x.hasPermission;
          }
          this.hasAnyEntityPermission = true;
        }
      });
      this.entityPermissionsLoaded = true;
    });
  };
  setProjectPermissions = (permissions: TPermission[]) => {
    runInAction(() => {
      permissions.forEach((x) => {
        if (x.hasPermission) {
          if (this.projectPermissions[x.code]) {
            this.projectPermissions[x.code].hasPermission = x.hasPermission;
          }
        }
      });
      this.projectPermissionsLoaded = true;
    });
  };

  getFeatures = async () => {
    if (StoreLayout.currentEntityId === 0) return;
    try {
      const response = await useGetFeatures(StoreLayout.currentEntityId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setFeatures(response.data);
      } else throw new Error();
    } catch (error) {
      console.error(error);
    }
  };

  getWorkspacePermissions = async () => {
    if (StoreLayout.currentWorkspaceId === 0) return;
    try {
      const response = await useGetMyWorkspacePermissions(StoreLayout.currentWorkspaceId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setWorkspacePermissions(response.data);
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Cannot load workspace permissions", "error");
    }
  };

  getEntityPermissions = async () => {
    if (StoreLayout.currentEntityId === 0) return;
    try {
      this.hasAnyEntityPermission = false;
      const response = await useGetMyEntityPermissions(StoreLayout.currentEntityId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setEntityPermissions(response.data);
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Cannot load entity permissions", "error");
    }
  };

  getProjectPermissions = async (idProject: number) => {
    try {
      const response = await useGetMyProjectPermissions(idProject);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setProjectPermissions(response.data);
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Cannot load entity permissions", "error");
    }
  };
}

const StorePermission = new store();
export default StorePermission;
