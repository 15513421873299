import React from "react";
import { makeAutoObservable } from "mobx";
import { getSurveyProjects } from "api/go/useGetSurveyProjects";
import { GetSurveyTags } from "api/go/useGetSurveyTags";
import { GetProjectStatuses } from "api/useGetProjectStatuses";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { sm_project, sm_tags } from "types/projects";
import { MarkAsNonTestProject } from "api/go/useMarkAsNonTestProject";
import MainStore from "MainStore";
import { DuplicateProject } from "api/go/useDuplicateProject";
import { DeleteProject } from "api/useDeleteProject";
import { getEntityInfo } from "api/go/useGetEntityInfo";

class Store {
  project_id: number = 0;
  data: sm_project[] = [];
  sortField = "";
  sortType = "";
  searchedValue: string = "";
  resultSearchValue: string = "";
  openCreateProjectPopup: boolean = false;
  openNonTestPopup = false;
  openPermissionsPopup = false;
  selectedRow: sm_project = null;
  tags: sm_tags[] = [];
  selected_tags_ids: number[] = [];
  statuses: string[] = ["Unscheduled", "Active", "Completed"];
  selected_statuses: string[] = [];

  deleteValue: string = "";
  openDeletePopup = false;
  editSettingsPopup = false;
  errorTextDelete: string = "";
  isDeleteBtnDisabled = true;

  QRCodeModalOpen: boolean = false;
  QRCodeLink: string = "";
  QRLincCopyMessage: string = "Copy link";
  entityLogoId: number;
  entityLogoUrl: string;

  openSetupOrgStructureConfirm: boolean = false;
  disableControlsOrgStructure: boolean = true;
  orgStructureCode: string = "";

  changeSort = (field: string, type: string) => {
    this.sortField = field;
    this.sortType = type;
    this.getProjects();
  };

  doLoad = () => {
    this.getProjects();
  };

  setQRModelOpen = (bool: boolean) => {
    this.QRCodeModalOpen = bool;
    this.QRLincCopyMessage = "Copy link";
  };

  setProjectId = (id: number) => {
    if (this.project_id !== id) {
      this.project_id = id;
    }
  };

  getProjects = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyProjects(
        StoreLayout.currentEntityId,
        this.resultSearchValue,
        this.sortField,
        this.sortType,
        this.selected_tags_ids,
        this.selected_statuses,
      );
      if ((response.status === 200 || response.status === 201) && response?.data) {
        this.data = response.data;
      } else throw Error();
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  GetSurveyTags = async () => {
    try {
      const response = await GetSurveyTags();
      this.tags = response;
    } catch (err) {
      MainStore.setSnackbar("Can't found tags", "error");
    }
  };

  GetStatuses = async () => {
    try {
      const response = await GetProjectStatuses();
      if ((response.status === 200 || response.status === 201) && response?.data) {
        this.statuses = response.data;
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Can't found statuses", "error");
    }
  };

  changeOpenCreateProjectPopup = (flag: boolean) => {
    this.openCreateProjectPopup = flag;
  };

  setSelectedRow = (template: sm_project) => {
    this.selectedRow = template;
  };

  openClickNonTestPopup = (flag: boolean) => {
    this.openNonTestPopup = flag;
  };

  openClickPermissionsPopup = (flag: boolean) => {
    this.openPermissionsPopup = flag;
  };

  openClickDeletePopup = (flag: boolean) => {
    this.openDeletePopup = flag;
  };

  changeEditSettingsPopup = (flag: boolean) => {
    this.editSettingsPopup = flag;
  };

  markAsTestProject = async (id: number, closeCallback: () => void) => {
    try {
      await MarkAsNonTestProject(id, !(this.selectedRow.test ?? false));
      await this.getProjects();
      closeCallback();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  };

  DuplicateSurveyProject = async (template: sm_project) => {
    try {
      if (!template) return;

      this.selectedRow = template;
      MainStore.changeLoader(true);

      const response = await DuplicateProject(this.selectedRow.id);
      MainStore.changeLoader(false);

      if (response?.data) {
        await this.getProjects();
        const filtered = this.data?.find((item) => item.id === response.data);
        const nameProject = filtered?.name.replace("(", " (");
        MainStore.setSnackbar(
          `"${this.selectedRow.name}" successfully duplicated, creating "${nameProject}"`,
        );
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  };

  clearDeleteValues = () => {
    this.deleteValue = "";
    this.isDeleteBtnDisabled = true;
    this.errorTextDelete = "";
  };

  onDeleteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.deleteValue = event.target.value;
    if (this.deleteValue !== "DELETE") {
      this.errorTextDelete = "Please type in the exact letters";
      this.isDeleteBtnDisabled = true;
    }
    if (this.deleteValue === "") {
      this.errorTextDelete = "";
      this.isDeleteBtnDisabled = true;
    }
    if (this.deleteValue === "DELETE") {
      this.errorTextDelete = "";
      this.isDeleteBtnDisabled = false;
    }
  };

  DeleteSubmit = async (id: number, closeCallback: () => void) => {
    try {
      MainStore.changeLoader(true);
      const response = await DeleteProject(id);
      MainStore.changeLoader(false);

      if ([200, 201].includes(response.status)) {
        MainStore.setSnackbar(`"${this.selectedRow.name}" successfully deleted`);
        await this.getProjects();
      }
      closeCallback();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  };

  getLink = () => {
    this.QRCodeLink = `${window["_env_"]?.REACT_APP_RESPOND_APP ?? process.env.REACT_APP_RESPOND_APP}/access-using-unique-id/${StoreLayout.currentEntityId}`;
  };

  copyMessage = () => {
    this.QRLincCopyMessage = "Copied!";
  };

  keyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.onSearchClicked();
    }
  };

  changeTag = (id: number) => {
    const index = this.selected_tags_ids.indexOf(id, 0);
    let new_arr = this.selected_tags_ids;
    if (index > -1) {
      new_arr.splice(index, 1);
    } else {
      new_arr = [...new_arr, id];
    }
    this.selected_tags_ids = new_arr;
    this.getProjects();
  };

  changeStatus = (status: string) => {
    const index = this.selected_statuses.indexOf(status, 0);
    let new_arr = this.selected_statuses;
    if (index > -1) {
      new_arr.splice(index, 1);
    } else {
      new_arr = [...new_arr, status];
    }
    this.selected_statuses = new_arr;
    this.getProjects();
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.getProjects();
  };

  changeValue = (name: string, value: string) => {
    this[name] = value;
  };

  constructor() {
    makeAutoObservable(this);
  }

  getCurrentEntity = async (idEntity: number) => {
    try {
      const response = await getEntityInfo(idEntity);
      const isOrgStructurePresent = Boolean(response.data.id_orgstructure);
      this.openSetupOrgStructureConfirm = !isOrgStructurePresent;
      this.disableControlsOrgStructure = !isOrgStructurePresent;
      this.orgStructureCode = response.data.id_orgstructureNavCode
    } catch (error) {
      console.error("Error occurred while getting entity info: ", error);
    }
  };

  setOpenModalConfirm = (bool: boolean) => {
    this.openSetupOrgStructureConfirm = bool;
  };

  getCurrentEntityLogo = async (id: number) => {
    try {
      const entityInfo = await getEntityInfo(id);
      if (entityInfo.logo_id) {
        this.entityLogoId = entityInfo.logo_id;
        this.entityLogoUrl = entityInfo.logo_url;
      }
    } catch (error) {
      console.error("Error occurred while getting entity logo: ", error);
    }
  };
}

const store = new Store();
export default store;
