import http from "./https";
import { createUrl } from "../helpers/url";
import { API_URL, API_TEMPLATE_URL_GO } from "constants/config";


export const getTemplateExcelEmployees = async (entityId: number) => {
  const query = { entity_id: entityId.toString() };
  const path = http.isAPIGO 
    ?  API_TEMPLATE_URL_GO+"/employee/employees/GetTemplateExcelEmployees" 
    : API_URL+"/employee/GetTemplateExcelEmployees" ;

  const url = createUrl({ path, query });

  try {
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      method: "GET",
      redirect: "follow"
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    });
  } catch (error) {
    console.error('Error downloading or saving file:', error);
  }
};