import http from "api/https";
import { createUrl } from "../../helpers/url";

export const GetAllFooterLanguageMessages = async (id: number): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/template/get_email_settings?id=" + id);
  } else {
    return http.get("/templ_template_comms/GetAllFooterLanguageMessages?id=" + id);
  }
};

export const getAllFooterLanguageMessages = (id: number): Promise<any> => {
  const query: { id: string; } = {id: id.toString(),};
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_survey/GetAllFooterLanguageMessages",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/sm_survey/GetAllFooterLanguageMessages",
      query: query,
    });
    return http.get(url);
  }
};
